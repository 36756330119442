<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'add-book' }" class="root">
               Add Book
            </router-link>
            <span class="child">/ Map Book Location </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Map Book Location</v-card-title>
            <v-card-text>
                <div class="bookdata">
                    <div class="bookInfo">
                        <!-- <v-row> -->
                        <v-col sm="6" class="value" lg="1">ISBN</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.isbn}}</v-col>
                        <v-col sm="6" class="value" lg="1">Title</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.title}}</v-col>
                        <v-col sm="6" class="value" lg="1">Copies</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.copies}}</v-col>
                    </div>
                    <!-- </v-row> -->
                    <div class="d-flex">
                        <v-col sm="6" class="value" lg="1">Edition</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.edition}}</v-col>
                        <v-col sm="6" class="value" lg="1">Volume</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.volume}}</v-col>
                        <v-col sm="6" class="value" lg="1">Book Format</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.bookFormat}}</v-col>
                    </div>
                    <div class="d-flex">
                        <v-col sm="6" class="value" lg="1">Book Type</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.bookType}}</v-col>
                        <v-col sm="6" class="value" lg="1">Category</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.category}}</v-col>
                        <v-col sm="6" class="value" lg="1">Publisher</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.publisher}}</v-col>
                    </div>
                    <div class="d-flex">
                        <v-col sm="6" class="value" lg="1">Author(s)</v-col>
                        <v-col sm="6" lg="11" class="data">: <span class="pr-3" v-for="(auth,index) in bookData.authors" :key="index">{{bookData.authors[index]}}</span></v-col>
                    </div>
                </div>
<div class="d-flex">
                <v-col class="copies p-0">Total Number of Copies : <span style="color: green;">{{bookData.copies}}</span></v-col>
                <v-col class="copies p-0">Number of Copies Not having rack : <span style="color: red;"> {{bookData.bookNotHavingRack}}</span></v-col>
</div>
                <v-data-table :headers="headers" :items="rackList" class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details> </v-text-field>
                            <span style="width: 20px;"></span>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.allocation="{ item }">
                        <div >
                           <span v-if="bookData.bookNotHavingRack > item.available ">
                            <label v-if="item.available < item.proposed" class="mt-1" style="color:red">
                              rack not avilable.</label>
                            <v-text-field type="number" dense outlined v-model="item.proposed"  min=0  :max="`${item.available}`"></v-text-field>
                           </span>
                           <span v-if="bookData.bookNotHavingRack <= item.available ">
                            <label v-if="item.proposed > bookData.bookNotHavingRack" class="mt-1" style="color:red">
                                {{bookData.bookNotHavingRack}} Book copies not available to map</label>
                            <v-text-field type="number" dense outlined v-model="item.proposed"  min=0  :max="`${bookData.bookNotHavingRack}`"></v-text-field>
                           </span>
                        </div>
                    </template>
                </v-data-table>
                <div class="copies"><v-btn class="m-3" color="success" @click="save">Save</v-btn></div>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";

    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            export_table_headers: {
                "Sr.no.": "srno",
                "Accession No": "access_no",
                "Book Status": "book_status",
                "Rack No": "rackNo",
                "Block No": "block_no",
            },
            bookData: null,
            racklist: [],
            valid: false,
            overlay: false,
            errorMsg: {
                roleLinkId: false,
            },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            showPicker: false,
            showPicker1: false,
            selectedDate: null,
            menu1: false,
            menu2: false,
            org: "",
            inSave: true,
            dialog: false,
            headers: [
                {
                    text: "Sr no",
                    align: "left",
                    sortable: true,
                    value: "srno",
                },
                {
                    text: "Rack No",
                    align: "left",
                    sortable: true,
                    value: "rackNo",
                },
                {
                    text: "Block No",
                    align: "left",
                    sortable: true,
                    value: "blockNo",
                },
                {
                    text: "Location Identifier",
                    align: "left",
                    sortable: true,
                    value: "locationI",
                },

                {
                    text: "Book Category",
                    align: "left",
                    sortable: true,
                    value: "bookCat",
                },
                {
                    text: "Capacity",
                    align: "left",
                    sortable: true,
                    value: "capacity",
                },
                {
                    text: "Available",
                    align: "left",
                    sortable: true,
                    value: "available",
                },

                { text: "Proposed Allocation", value: "allocation", sortable: false },
                //  { text: "Book Item(s)", value: "bookItem", sortable: false },
            ],
            bookId:null,
            bookCategoryList: [],
            roleLink_list: [],
            blockList: [],
            role_list: [],
            editedIndex: -1,
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Book Item" : "Edit Book Item";
            },

        },

        // watch: {
        //     dialog(val) {
        //         val || this.close();
        //     },
        //     overlay(val) {
        //         val = () => {
        //             this.overlay = false;
        //         };
        //     },
        // },
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad() {
                console.log("params----", this.$route.params.bookId);
                let data = {
                    bookId: this.$route.params.bookId,
                };
                this.bookId = this.$route.params.bookId
                this.overlay = true;
                axios
                    .post("/Librarian/getBookRackData", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.bookData = res.data.bookData;
                            this.rackList = res.data.rackList;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            save(){
console.log('--------',this.rackList);
let arr = [];
let data = {}
for(let i in this.rackList){
    console.log('rack----',this.rackList[i]);
    if(this.rackList[i].proposed != ''){
arr.push(this.rackList[i])

    }
     data = {
        arr:arr,
        bookId:this.bookId
    }
}
if(arr.length != 0){
                    axios
                    .post("/Librarian/mapBookLocation", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                             this.showSnackbar("#4caf50", "Location added sucessfully.");
                            this.onLoad();
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
console.log('arr----',arr);
}else{
                        this.showSnackbar("#b71c1c", "Please enter allocation count."); // show snackbar
}
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // ==this.$refs.form.reset()
                // setTimeout(() => {
                //     this.editedItem = Object.assign({}, this.defaultItem);
                //     this.editedIndex = -1;
                // }, 300);
            },

            isValidated() {
                if (this.editedItem.roleLinkId) {
                    return true;
                } else {
                    if (!this.editedItem.roleLinkId) {
                        this.errorMsg.roleLinkId = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
    .data {
        padding-left: 20px;
    }
    .bookdata {
        border: 2px solid gray;
        background: #d3d3d330;
        margin: 1rem 0rem 1rem 0rem;
        border-radius: 5px;
    }
    .value {
        white-space: nowrap;
        font-weight: bold;
        color: black;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .isdonated {
        margin-top: 15px;
        padding-left: 15px !important;
    }
    .bookInfo {
        display: flex;
    }
    .copies {
        text-align: center;
        color: blue;
        font-weight: bold;
    }
    @media only screen and (max-width: 600px) {
        .bookInfo {
            /* display: block; */
        }
    }
</style>
